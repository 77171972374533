import React from 'react';
import SEOTags from '~/components/SEOTags';
import useRenderComponent from '~/hooks/useRenderComponent';
import Layout from '~/layouts/ThemeLayout';
import { getPageStruct, renderSectionNew } from '~/utils/pageTwo';
import { getTheme } from '~/utils/theme';

const pageStruct = getPageStruct(getTheme(), 'not-found');
const sections = pageStruct?.children
    ?.sort((a: any, b: any) => a.order - b.order)
    .map((item: any) => {
        const Section = renderSectionNew(item);
        if (!Section) return null;

        return <React.Fragment key={item.id}>{<Section />}</React.Fragment>;
    });

const Error = () => {
    return (
        <Layout>
            <SEOTags title={'404'} />
            {sections.map((section: any) =>
                React.isValidElement(section) ? React.cloneElement(section as React.ReactElement, {}) : section
            )}
        </Layout>
    );
};

export default Error;
